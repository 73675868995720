/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@include mat.core();
$visibility-primary: mat-palette($mat-indigo);
$visibility-accent: mat-palette($mat-pink, A200, A100, A400);
$visibility-warn: mat-palette($mat-red);
$visibility-theme: mat-light-theme($visibility-primary, $visibility-accent, $visibility-warn);
@include angular-material-theme($visibility-theme);
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '~bootstrap/dist/css/bootstrap.css';
@import '/src/assets/css/buttons.scss';
@import '/src/assets/css/chart.scss';
@import '/src/assets/css/shared.scss';
@import '/src/assets/css/mediaQueries.scss';
@import '/src/assets/css/forms.scss';
@import '/src/assets/css/visone-popup.scss';
@import '/src/assets/css/visone-popup.scss';
@import '/src/assets/fonts/avenir/stylesheet.scss';
@import '/src/assets/css/reports.scss';

// ./src/assets/fonts/avenir/stylesheet.css
body {
  overflow-x: hidden;
  //AXE
}

/* =============================================================
Info Component Styles
============================================================= */

div {
  font-family: 'Avenir';
}

.card-body p {
  font-size: 0.8rem;
  margin-bottom: 0rem;
}

.card-title {
  margin-bottom: 0.25rem !important;
}

.card-body img {
  padding-top: 25px;
  padding-bottom: 50px;
  height: 300px;
  margin: auto;
}

.card-body {
  width: 100% !important;
  height: 90%;
}

.card {
  width: 100% !important;
  height: 100%;
}

.card-detail-bm h4 {
  font-weight: 700;
}

/* =============================================================
Filter Table Component Styles
============================================================= */
.filter-table input {
  font-size: 12px;
}

/* =============================================================== */
/* Panel Header Styles */
/* =============================================================== */
.right-panel-menu {
  margin-bottom: 10px;
}

.right-panel-menu h1 {
  display: inline;
  text-transform: uppercase;
  font-size: 1.75rem;
}

/* =============================================================== */
/*  Right Panel Styles */
/* =============================================================== */

.right-panel {
  padding: 10px;
  background-color: #d3d3d3;
}

/* =============================================================== */
/*  Smart Table Styles */
/* =============================================================== */
.table-health-triangle img {
  width: 20px;
  // margin-right: 5px;
}

.poweredby {
  text-align: right;
  font-size: 0.8em;
}

@page {
  size: auto;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.custom-dialog-container .mat-dialog-container {
  /* add your styles */
  padding: 0 !important;
  transform: none;
  border: 1px solid #000;
  border-radius: 8px;
  overflow-y: hidden !important;
}

.custom-dialog-container-no-border-radius .mat-dialog-container {
  /* add your styles */
  padding: 0 !important;
  transform: none;
  border: 1px solid #000;
  overflow-y: hidden !important;
}

.cdk-overlay-container {
  z-index: 2000;
}

:host ::ng-deep .cdk-global-scrollblock {
  top: 0px !important;
}

.cdk-global-scrollblock {
  top: 0px !important;
}

.pointer {
  cursor: pointer;
}

.actioned-thead {
  width: 1% !important;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 5px !important;
}

.bg-green {
  // background: linear-gradient(to bottom, #71bd45 40%, #a5de6d 100%);
  background: #71bd45;
}
.bg-red {
  // background: linear-gradient(to bottom, #c92f20 40%, #b66e66 100%) !important;
  background: #c92f20;
}
.bg-yellow {
  // background: linear-gradient(to bottom, #fcb95d 40%, #eab93b 100%) !important;
  background: #fcb95d;
}
.bg-blue {
  // background: linear-gradient(to bottom, #4780c8 40%, #7a9dc8 100%) !important;
  background:  #4780c8;
}
.bg-grey {
  // background: linear-gradient(to bottom, #b4b4b4 40%, #cecdcd 100%) !important;
  background: #b4b4b4;
}

.border-green {
  border: 3px solid #71bd45 !important;
}
.border-red {
  border: 3px solid #c92f20 !important;
}
.border-yellow {
  border: 3px solid #fcb95d !important;
}
.border-blue {
  border: 3px solid #4780c8 !important;
}
.border-grey {
  border: 3px solid #b4b4b4 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
// //------------ AXE DARKTHENE

// .global-darktheme {
//   background: #181818 !important;
// }

// .card-body {
//   background: #181818 !important;
// }


// //DNA
// .right-panel {
//   padding: 10px;
//   background: #181818 !important;

// }

// .video-device-panel__panel-container {
//   background: #181818 !important;
// }

// // MAIN MENU
// .main-menu {
//   background: #181818 !important;
//   color: white;
// }

// //rescan
// .panel-body {
//   background: #181818 !important;
// }

// .admin {
//   background: #181818 !important;
//   color: white;
// }

// //POPUP CONTAINER

// #main-popup {
//   background: #181818 !important;
// }

// //HEALTH CARD

// .health-card-dark {
//   background: #181818 !important;
// }

// th,
// td {
//   background: #181818 !important;
//   color: white;
// }

// button {
//   background: #181818 !important;
//   color: white;
//   border: 1px solid white !important;

// }

// body {
//   color: white !important;
// }

// //------------ AXE DARKTHENE