#confirmMessage {
  position: absolute !important;
  top: 0px !important;
}

.tut-zindex-topbar {
  z-index: 1200;
}
.tut-zindex {
  z-index: 12;
}
.tut-show {
  display: block !important;
}
.tut-congrats-btn {
  position: absolute;
  bottom: 150px;
  top: 25px;
  right: -1368px;
  z-index: 1200;
}
.tut-congrats {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_congratulations.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 600px;
  height: 120px;
  margin: 0px;
  word-wrap: normal;
  font-size: 20px;
  position: absolute;
  padding: 30px 170px 30px 20px;
  bottom: 150px;
  top: 80px;
  right: -1550px;
  z-index: 1200;
}
.tut-addDevice {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_add_device.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 600px;
  height: 120px;
  margin: 0px;
  word-wrap: normal;
  font-size: 20px;
  position: absolute;
  padding: 30px 60px;
  bottom: 150px;
  top: 30%;
  left: 5%;
  z-index: 12;
}
.tut-reenter {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_device_exists.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  width: 600px;
  margin: 0px;
  font-size: 20px;
  position: absolute;
  padding: 20px 10px;
  bottom: 150px;
  top: 2%;
  left: 30%;
  z-index: 10;
}
.tut-selectdevice {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_monitor.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 600px;
  margin: 0px;
  font-size: 25px;
  text-align: center;
  position: absolute;
  padding: 10px;
  bottom: 150px;
  left: 27%;
  z-index: 10;
}
.tut-polycom {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_monitor.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 600px;
  margin: 0px;
  font-size: 25px;
  text-align: center;
  padding: 10px;
  top: 100px;
  left: 230px;
  z-index: 10;
}
.tut-virtual {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_site.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 600px;
  margin: 0px;
  margin-left: auto;
  font-size: 25px;
  text-align: center;
  padding: 10px;
  top: 50px;
  right: 10px;
  z-index: 10;
}
.tut-verify {
  position: absolute;
  display: none;
  background-image: url('~/assets/img/tutorial/bg_verify.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 600px;
  margin: 0px;
  margin-left: auto;
  /* margin-right: auto; */
  font-size: 20px;
  text-align: center;
  padding: 10px;
  top: 39%;
  right: 16%;
  z-index: 10;
}
.tut-verify p {
  position: relative;
  top: 35px;
  text-align: center;
}
.tut-site {
  display: none;
  background-image: url('~/assets/img/tutorial/bg_site.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 600px;
  margin: 0px;
  margin-left: auto;
  /* margin-right: auto; */
  font-size: 20px;
  text-align: center;
  padding: 10px;
  top: 50px;
  right: 33%;
  z-index: 10;
}

.tut-collector {
  position: absolute;
  display: none;
  background-image: url('~/assets/img/tutorial/bg_download_collector.png');
  background-repeat: no-repeat;
  background-size: contain;
  right: 44%;
  height: 150px;
  margin: 0px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  top: -7%;
  z-index: 10;
}
.top-btn-row {
  margin-left: 8%;
  margin-right: 1%;
}
.btn-visone-download img {
  width: 100%;
  height: auto !important;
  margin: 0px !important;
  padding: 0px !important;
}
#visone-container {
  z-index: 10;
}
#visone-overlay {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: 10;
}
.del-me {
  display: none;
}
.delete-btn {
  font-size: 12px;
  border-radius: 20px;
  width: 70px;
  height: 40px;
  background: black;
  color: white;
}

tbody tr:nth-child(odd) {
  background-color: whitesmoke;
}
tbody tr:nth-child(odd):hover {
  background-color: whitesmoke;
}
table tr:nth-child(even):hover {
  background: white;
}
ul {
  list-style-type: none;
  display: inline-block;
}
ul h1 {
  font-size: 1.2em;
  padding: 20px 40px;
}
li {
  text-align: center;
  float: left;
}
// li:hover {
//   color: white;
// }

.table {
  width: 90%;
  margin: 5%;
}
.table .ng-tns-c2-5 {
  text-align: left;
}

.fa-close {
  position: absolute;
  font-size: 36px;
  right: 20px;
  top: 20px;
}
.zip-text {
  width: 200px;
  z-index: 999;
  position: absolute;
  left: 100px;
  display: block;
  /* top: 30px; */
}

.new-update-ctn {
  .new-update-ch-1 {
    width: 100%;
    .image-ctn {
      display: flex;
      justify-content: flex-end;
      img {
        cursor: pointer;
        width: 20px;
        margin: 20px 35px 16px 0;
      }

      @media only screen and (max-width: 1400px) {
        img {
          margin: 20px 25px 16px 0;
        }
      }
      .overlay {
        cursor: pointer;
        position: absolute;
        img {
          cursor: pointer;
          opacity: 0;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  position: absolute;
  top: -210px;
  height: 210px;
  width: 100%;
  background-color: #f2f2f2;
}

.new-update {
  color: #4a81c7;
  cursor: pointer;
}

.pop-site-name {
  position: absolute;
  top: 16px;
  left: 17px;
  font-weight: 700;
}

.status-green {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #76d132;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cecece;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #347ecf;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid white;
}

input:focus + .slider {
  box-shadow: 0 0 1px #347ecf;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #76d132;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.status-red {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #ff4b4b;
}
.status-grey {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: rgb(107, 107, 107);
}
.status-blue {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: rgb(0, 102, 255);
}

.btn-verify {
  position: relative;
  text-align: center;
  padding: 3px 10px;
  border-radius: 3px;
  cursor: pointer;
}
.btn-verify:nth-child(1) {
  background: white;
}
