.not-button:active {
  color: gray;
  background: linear-gradient(to top, #d3d3d3, #f6f6f6) !important;
}

.not-button:hover {
  opacity: 1 !important;
  box-shadow: none !important;
  border: none !important;
  cursor: default !important;
  color: gray !important;
  background: linear-gradient(to top, #d3d3d3, #f6f6f6) !important;
}

.not-button:hover h3,
.not-button:hover p {
  color: gray !important;
}

.btn-long {
  width: 100%;
  height: 95px;
}

.btn-long h1 {
  font-size: 3.5rem;
  margin: 10px 0px;
}

.btn:focus,
.progress {
  outline: none !important;
}

.btn-sm {
  font-size: 15px !important;
  width: 100% !important;
  height: auto !important;
}

.btn-sm:hover {
  border: none;
}

.btn-ht100 {
  width: 100% !important;
  height: 100% !important;
}

.btn-reg {
  width: 100% !important;
  height: 95px !important;
}

.btn-ht33 {
  width: 100%;
  height: 33%;
}

.btn-33 {
  width: 33%;
}

.btn-50 {
  width: 50%;
}

.btn {
  white-space: normal !important;
}

button {
  font-size: 1.5em;
}

button {
  outline: none !important;
  border-style: none !important;
  color: gray;
  background: linear-gradient(to top, #d3d3d3, #f6f6f6);
  position: relative !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

button:hover,
button:hover h3,
button:hover p {
  color: lightgray;
}

.btnpressed {
  color: white;
  border: 2px solid #191919 !important;
  opacity: 0.78 !important;
  background-color: #4e4e4e;
  background: linear-gradient(270deg, #7a7a7a 0%, #9c9c9c 100%) !important;
}

button:active {
  color: #4e4e4e !important;
  opacity: 0.78 !important;
  background-color: #4e4e4e;
  background: linear-gradient(270deg, #7a7a7a 0%, #9c9c9c 100%) !important;
}

button:active h3,
button:active p {
  color: #4e4e4e !important;
}

button h3 {
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 0;
}

button h3 small {
  height: 0.2rem !important;
}

button h4 {
  margin: 0px;
}

button p {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}

.audio-user {
  height: 75px !important;
}

.audio-user h3 {
  font-size: 24px !important;
}

.audio-user p {
  font-size: 12px !important;
}

.firmware-users img {
  height: auto;
  width: 75px;
}

.firmware-users {
  padding: 0px 15%;
  width: 100%;
  height: 95px;
}

.firmware-devices img {
  height: auto;
  width: 50px;
}

.firmware-devices {
  padding: 0px 15% !important;
  width: 100%;
  height: 95px;
}

.gray {
  color: gray;
}

.green {
  color: #75d033;
}

.red {
  color: #c92d14;
}

.yellow {
  color: #ffb952;
}

.btnbacklite-default {
  color: gray !important;
  background: linear-gradient(to top, #d3d3d3, #f6f6f6) !important;
  transition: all 1000ms;
}

.btnbacklite {
  background: linear-gradient(to top, #608ec5, #b5cce9) !important;
  color: black;
  transition: all 1000ms;
}

@-webkit-keyframes examplebtn2 {
  0% {
    background: linear-gradient(to top, #608ec5, #b5cce9);
  }

  100% {
    background: linear-gradient(to top, #d3d3d3, #f6f6f6);
  }
}

/* Standard syntax */
@keyframes examplebtn2 {
  0% {
    background: linear-gradient(to top, #608ec5, #b5cce9);
  }

  100% {
    background: linear-gradient(to top, #d3d3d3, #f6f6f6);
  }
}