.report-circle{
  border: 4px solid;
  border-radius: 25px;
  background: white;
  height: 50px;
  width: 50px;
}
.report-oval{
  border: 4px solid;
  border-radius: 25px;
  background: white;
  height: 50px;
  width: 120px;
}
.report-circle-number{
  position: relative;
  top: 10px;
}
.report-oval-number{
  position: relative;
  top: 10px;
}
.report-bold{
  font-weight: 900 !important;
  font-size: 3.2rem !important;
}
.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
  width: 2.5rem !important;
}
.main-report-panel .main-report-row{
  height:600px !important;
  overflow:auto !important;
}

