a,
.panel-heading,
.cursor-pointer,
.members-pop img,
.nav-img-menu,
.btn-verify,
table .row,
table td,
.slack-panel,
.fa-trash,
.fa-edit {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.btn-verify {
  background: none;
}

.is-invalid3 {
  display: inline !important;
  color: red !important;
  font-size: 10px;
}

.is-invalid2 {
  color: red !important;
}

.is-invalid {
  color: red !important;
  border: 3px solid red !important;
  border-color: red !important;
}

.invalid-feedback {
  width: auto;
  font-size: 1.5rem;
  position: relative;
  display: inline-block !important;
  clear: left;
  float: left;
  left: -10px;
  bottom: 42px;
  height: 0px;
}

input,
input:valid,
input:focus,
.form-control:focus,
select {
  border: 3px solid lightgray !important;
  outline: none !important;
}

.fullscreen {
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999 !important;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* remove default arrow */
  background-image: url('~/assets/img/shared/down-black-pointer.png');
  /* add custom arrow */
  background-repeat: no-repeat !important;
  background-position-x: 98% !important;
  background-position-y: 60% !important;
  height: 100% !important;
}

.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.signup .btn-success {
  background: #8fca58 !important;
}

.signup .btn-primary {
  background: #4a81c7 !important;
}

.signup button {
  color: #fff;
  font-family: Avenir;
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.signup input,
.signup select,
.dropdown-menu {
  border-radius: 0px;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
}

.signup {
  padding: 25px;
  opacity: 0.9;
  background: white;
  width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 100px;
}

.signup img {
  width: 100%;
}

.terms {
  color: #4a81c7;
  font-family: Avenir;
  font-size: 14px;
}

.signup h4 {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup h4 span {
  background: #fff;
  margin: 0 10px;
}

.signup h4:before,
.signup h4:after {
  background: black;
  height: 1px;
  flex: 1;
  content: '';
}

.signup span {
  color: black;
  font-size: 16px;
}

#confirmMessage {
  position: absolute !important;
  /* top: 15px !important; */
}

.password2-text {
  position: relative;
  right: -210px;
  top: -3px;
}

.phonetext {
  right: 10px;
  position: absolute;
  width: 207px;
}

.alert {
  font-size: 14px;
}

.password-text {
  width: 240px;
  position: absolute;
  /* top: 38px; */
  right: 10px;
}
